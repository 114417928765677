<template>
  <div>
    <div v-if="getLoaded()">
      <div class="data-control">
        <MemberSearch />
        
        <div class="btn-set">
          <router-link to='member/select-type' class="btn btn-add">
            <i class="fa fa-plus"></i> เพิ่ม<span class="mobile-hide">สมาชิก</span>
          </router-link>

          <a href="#" @click="clickExportExcel" class="btn btn-export">
            Export
          </a>
        </div>
      </div>
  
      <div v-if="members.row==0">
        <EmptyBlockComp />
      </div>
      <section v-else class="data-list">
        <table class="tb-data">
          <colgroup>
            <col class="col-name" />
            <col class="col-phone" />
            <col class="col-email" />
            <col class="col-role" />
            <col class="col-date" />
          </colgroup>
          <thead>
            <tr>
              <th>ชื่อ-นามสกุล</th>
              <th>หมายเลขโทรศัพท์</th>
              <th>อีเมล์</th>
              <th>สถานะ</th>
              <th>แก้ไขล่าสุด</th>
            </tr>
          </thead>
  
          <tbody>
            <tr v-for="(item, i) in members.result" :key="i">
              <td>
                <div class="item-detail">
                  <router-link :to="'member/edit/' + item.MID" class="item-title">
                    {{ item.first_name }}
                    {{ item.last_name }}
                  </router-link>
                  <div v-if="item.business_type==9">{{ item.company_name }}</div>
                </div>
                <ul v-if="haveEditRole()" class="item-action">
                  <li><router-link :to="'member/edit/' + item.MID" class="edit">แก้ไข</router-link></li>
                  <li><a href="#" @click.prevent="deleteItem(item.MID)" class="delete" >ลบ</a></li>
                </ul>
                <ul v-else class="item-action">
                  <li><router-link :to="'member/edit/' + item.MID" class="edit">ดูข้อมูล</router-link></li>
                </ul>
              </td>
              <td><a :href="'tel:'+item.phone">{{ item.phone }}</a></td>
              <td><a :href="'mailto:'+item.email">{{ item.email }}</a></td>
              <td>
                {{ item.type | memberTypeTitle }}
                <span v-if="item.type==20 && item.type_request==9">
                  <br><span class="highlight2">ขอเป็นช่าง/ผู้รับเหมา</span>
                </span>
                <span v-if="item.type==10" class="highlight">
                  ({{ item.constructor_type | constructorTypeTitle }})
                </span>
              </td>
              <td>{{ item.date_modified | formatDate }}</td>
            </tr>
          </tbody>
  
          <tfoot>
            <tr>
              <th>ชื่อ-นามสกุล</th>
              <th>หมายเลขโทรศัพท์</th>
              <th>อีเมล์</th>
              <th>สถานะ</th>
              <th>แก้ไขล่าสุด</th>
            </tr>
          </tfoot>
        </table>
  
      </section>
      
      <div class="data-control control-foot">
        <Pagination slug='member' :limit="queryParams.limit" :total="members.total" />
      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
import {apiRequest} from '@/utils/axios/axiosInstance.js';
import {const_data_limit} from '@/variables/const.js';
import {cloneObject} from '@/utils/objects.js'

import EmptyBlockComp from '@/components/common/EmptyBlockComp.vue'
import MemberSearch   from '@/components/member/MemberSearch.vue'
import Pagination     from '@/components/common/Pagination.vue'

export default {
  name: 'Member',
  data() {
    return {
      members: {
        total: 0
      },
      queryParams:{
        limit: const_data_limit, 
        page: 1
      }
    }
  },
  components: {
    EmptyBlockComp,
    MemberSearch,
    Pagination,
  },
  watch:{
    $route() {
      this.loadPage();
    }
  },
  created() {
    this.$store.commit('setPageTitle', 'Members');
    this.$store.commit('setHeadTitle', 'Members');
    this.loadPage();
  },
  methods: {
    /**
     * have edit data permisstion
     */
    haveEditRole() {
      return this.$store.state.user.type>=50;
    },

    getLoaded() {
      return !this.$store.state.show_loader;
    },

    /**
     * Load member list
     */
    loadPage() {
      this.$store.commit('setLoaderShow', true);

      apiRequest
        .get( '/member', { params: this.prepareQueryParams() })
        .then( (res) => {
          this.members = res.data;
          this.members.total = Number(this.members.total);

          this.$store.commit('setLoaderShow', false);
        })
    },

    /**
     * Delete data item by member id
     */
    deleteItem(id) {
      let conf = confirm("Do you want to delete this item?");
      if(conf) {
        this.$store.commit('setLoaderShow', true);
        apiRequest
          .post('/member/delete', {member_id:id})
          .then(() => this.loadPage());
      }
    },

    /**
     * Click event handler for export member data to excel 
     */
    clickExportExcel() {
      apiRequest
        .get( '/member/export', { params: this.prepareQueryParams() })
        .then( (res) => {
          window.location.href = res.data.uri;
        })
    },

    prepareQueryParams() {
      let queryParams = cloneObject(this.queryParams);
      if(this.$route.query.q!=='') {
        queryParams.q = this.$route.query.q;
      }
      if(this.$route.query.type!=0){
        queryParams.type = this.$route.query.type;
      }
      if(this.$route.query.page!==''){
        queryParams.page = this.$route.query.page;
      }
      return queryParams;
    }
  }
}
</script>


<style scoped>
.btn-export {
  background: #b00;
  color: #fff;
}
.tb-data .col-email {width: 25%;}
.tb-data .col-role  {width: 15%;}
.tb-data .col-date  {width: 15%;}
.tb-data .highlight {
  display: inline-block;
  color: #960;
}
.tb-data .highlight2 {
  display: inline-block;
  padding: 0 8px;
  background: #c00;
  color: #fff;
}

.item-action {margin-top: 10px;}
</style>