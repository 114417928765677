<template>
  <div class="data-search">
  <form @submit="searchSubmit" method="get" autocomplete="off">
    <fieldset>
      <input type="text" name="q" v-model="search.q" placeholder="ค้นชื่อและโทรศัพท์"  autocomplete="off">
      <select name="type" v-model="search.type">
        <option value="0">แสดงทุกสถานะ - -</option>
        <option value="10">ผู้ใช้งาน + ช่าง/ผู้รับเหมา</option>
        <option value="15">กำลังขอสถานะช่าง/ผู้รับเหมา</option>
        <option value="20">ผู้ใช้งาน</option>
      </select>
      <span class="search-opt">
        <!-- <input type="checkbox" name="req_const" v-model="req_const"> View Request Constructor Status Only -->
      </span>
      <button type="submit">
        <i class="fa fa-search fa-lg"></i>
      </button>
    </fieldset>
  </form>
</div>
</template>

<script>
// import {cloneObject} from '@/functions/objects.js'

export default {
  data() {
    return {
      search: {
        q:'',
        type:0,
        req_const: ''
      }
    }
  },
  created() {
    if(this.$route.query.q!==undefined) {
      this.search = this.$route.query;
    }
    if(this.$route.query.type!==undefined) {
      this.type = this.$route.type;
    }
  },
  methods: {
    searchSubmit() {}
  }
}
</script>